if (document.domain.indexOf('medscape.org') != -1) { // must run before DocReady
	window.s_not_pageview = true;
} else {
	window.s_pagename = document.domain.replace(/^www./,'') + '/404';
}

import objectFitImages from 'object-fit-images';

$(() => {

	try {
		if (document.domain.indexOf('medscape.org') != -1) {
			window.wmdPageview('cme.medscape.com/404', 1);
		}
	}
	catch(e) {}

    $('#searchForm').submit(() => {
        wmdPageLink('404-search_sub');
    })

    $('#pillarLink a').each(function () {
        $(this).click(() => {
            wmdTrack(this.getAttribute('rel'));
        })
    })

    $('#featuredStory a').each(function (i) {
        $(this).click(() => {
            wmdTrack(`404-stories_${i + 1}`);
        });
    });

    objectFitImages('img.featimg');
});